import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { colors } from "../theme";

const Wrapper = styled.div`
  margin-top: 150px;

  h1 {
    font-weight: 500;
    text-align: center;
    color: ${colors.blackText};
  }
`;

const FAQWrapper = styled.div`
  margin-top: 50px;

  .MuiSvgIcon-root {
    color: white;
  }
`;

export const FAQ = ({
  questions,
}: {
  questions: { title: string; text: string }[];
}) => {
  return (
    <Wrapper>
      <h1>FAQ</h1>
      <FAQWrapper>
        {questions.map(({ title, text }) => (
          <Accordion
            key={title}
            sx={{ bgcolor: colors.pink400, color: "white" }}
          >
            <AccordionSummary
              aria-controls={`panel-${title}-content`}
              id={title}
              expandIcon={<ExpandMoreIcon />}
            >
              <strong style={{ margin: 10 }}>{title}</strong>
            </AccordionSummary>
            <AccordionDetails sx={{ background: colors.pink600 }}>
              <p>{text}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </FAQWrapper>
    </Wrapper>
  );
};
