import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";

type Props = {
  id: string;
  width?: number;
  label: string;
  value: string;
  options: { value: string[number]; label: string }[];
  setValue: (v: string) => void;
};

export const Select = ({
  id,
  width = 120,
  label,
  value,
  options,
  setValue,
}: Props) => {
  return (
    <Box sx={{ minWidth: width }}>
      <FormControl variant="filled" fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <MuiSelect
          labelId={id}
          id={`id-${id}`}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          {...(value && {
            IconComponent: () => (
              <IconButton
                aria-label="clear icon"
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onClick={() => {
                  setValue("");
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          })}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};
