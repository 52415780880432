import { css } from "@emotion/react";
import styled from "@emotion/styled";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Quicksand } from "next/font/google";

import { colors } from "../theme";

const inter = Quicksand({ subsets: [], display: "swap" });

const containedPrimary = css`
  color: ${colors.black};
  background-color: ${colors.pink500};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:hover {
    background-color: ${colors.darkPink2};
  }
`;

const containedSecondary = css`
  color: ${colors.white};
  background-color: ${colors.pink600};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:hover {
    background-color: ${colors.darkPink2};
  }
`;

const textCSS = css`
  font-size: 1rem;
  font-weight: 400;
  padding: 0;
  color: ${colors.darkPink2} !important;
  background: transparent;

  &:hover {
    background-color: transparent;
  }
`;

const StyledButton = styled(MuiButton)<ButtonProps>`
  border-radius: 20px;
  padding: ${({ size }) => (size === "small" ? "6px 20px" : "10px 30px")};
  letter-spacing: 1.2px;
  text-transform: none;

  ${({ variant, color }) =>
    variant === "contained" && color === "primary" && containedPrimary}
  ${({ variant, color }) =>
    variant === "contained" && color === "secondary" && containedSecondary}
  ${({ variant }) => variant === "text" && textCSS}
`;

type Props = ButtonProps & { loading?: boolean; gid?: string };

export function Button({
  children,
  variant = "contained",
  color = "primary",
  loading,
  disabled,
  ...props
}: Props) {
  return (
    <StyledButton
      className={inter.className}
      variant={variant}
      color={color}
      disableRipple={variant === "text"}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <CircularProgress color="inherit" size={24} /> : children}
    </StyledButton>
  );
}
