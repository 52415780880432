import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import { Modal as MuiModal, ModalProps as MuiModalProps } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Quicksand } from "next/font/google";

const inter = Quicksand({ subsets: [], display: "swap" });

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "rawstyle",
})<BoxProps & { rawstyle?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 750px;
  max-height: 90vh;
  width: 90%;
  border-radius: 8px;
  overflow: auto;
  background: ${({ rawstyle }) => (rawstyle ? "" : "#f4f4f4")};
  padding: ${({ rawstyle }) => (rawstyle ? "" : "10px 20px")};

  h2 {
    margin-top: 15px;
  }
`;

export type ModalProps = MuiModalProps & {
  title?: string;
  open: boolean;
  rawStyle?: boolean;
  onClose: () => void;
};

export function Modal({
  title,
  open,
  rawStyle = false,
  onClose,
  children,
}: ModalProps) {
  return (
    <MuiModal open={open} onClose={onClose} disableScrollLock>
      <StyledBox className={inter.className} rawstyle={rawStyle}>
        <h1 style={{ marginBottom: 5, marginTop: 8 }}>{title}</h1>
        <IconButton
          aria-label="clear icon"
          sx={{ position: "absolute", right: 10, top: 12 }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>
        {children}
      </StyledBox>
    </MuiModal>
  );
}
