import styled from "@emotion/styled";
import Image from "next/image";

import { fbIcon, igIcon, Link, logo, ttIcon } from "..";
import { colors } from "../theme";

const Wrapper = styled.footer`
  position: relative;
  padding: 40px 10px;
`;

const BackgroundPlate = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 0;
  height: 100%;
  width: 100vw;
  background-color: ${colors.pink400};
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    color: ${colors.pink600};
    font-weight: 600;
    text-align: center;
  }
`;

type Props = {
  links?: { href: string; label: string }[];
};

export const Footer = ({ links = [] }: Props) => (
  <Wrapper>
    <BackgroundPlate />
    <ContentWrapper>
      <Image src={logo} alt="logo" height={60} />
      <SocialMediaWrapper>
        <a
          href="https://www.facebook.com/profile.php?id=100095198222195"
          target="_blank"
        >
          <Image src={fbIcon} alt="fb-icon" height={45} width={45} />
        </a>
        <a href="https://www.instagram.com/sublyfe.se" target="_blank">
          <Image src={igIcon} alt="fb-icon" height={45} width={45} />
        </a>
        <a href="https://www.tiktok.com/@sublyfe.se" target="_blank">
          <Image src={ttIcon} alt="fb-icon" height={45} width={45} />
        </a>
      </SocialMediaWrapper>
      <LinksWrapper>
        {links.map((link) => (
          <Link key={link.href} href={link.href}>
            {link.label}
          </Link>
        ))}
      </LinksWrapper>
      <p>Sublyfe copyright 2023</p>
    </ContentWrapper>
  </Wrapper>
);
