import { css } from "@emotion/react";
import styled from "@emotion/styled";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

import { colors } from "../theme";

const outlinedCss = css`
  margin-top: 18px;

  .MuiFormLabel-root {
    color: black !important;
    transform: translate(24px, 16px) scale(1);
  }

  .MuiInputLabel-shrink {
    transform: translate(24px, -18px) scale(0.75);
  }

  .MuiOutlinedInput-root {
    border-radius: 40px;
    background: white;
    border: none;
    outline: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-input {
    padding: 16.5px 24px;
  }
`;

const filledCss = css`
  .MuiFilledInput-input {
    color: ${colors.black};
  }

  .Mui-focused {
    color: ${colors.pink600} !important;

    ::after {
      border-bottom-color: ${colors.pink600};
    }
  }

  .MuiFilledInput-root:after {
    border-bottom-color: ${colors.pink600};
  }
`;

const StyledMuiTextField = styled(MuiTextField)<{ variant?: string }>`
  ${({ variant }) => variant === "outlined" && outlinedCss}
  ${({ variant }) => variant === "filled" && filledCss}
`;

export const TextField = (props: TextFieldProps) => {
  // console.log("TextField", props);

  return <StyledMuiTextField margin="dense" {...props} />;
};
