export const colors = {
  white: "#ffffff",
  black: "#000000",
  blackText: "#283044",
  lightPink: "#f9f1f0",
  pink: "#f4b9ba",
  darkPink: "#e86f71",
  darkPink2: "#ab8283",
  pink100: "#FFF4F4",
  pink200: "#E6D5CE",
  pink300: "#DCCCC4",
  pink400: "#E4CABD",
  pink500: "#F5C8B4",
  pink600: "#AB8283",
  red200: "#f9d3ce",
  red400: "#F84F31",
  green200: "#d9ead8",
  green400: "#23C552",
  // alert colors
  red: "#F84F31",
  yellow: "#F8C31F",
  green: "#23C552",
};
