import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useBoUserAuthContext } from "context/BoUserAuthContext";
import { Quicksand } from "next/font/google";
import Image from "next/image";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { Button, colors, Link, logo, Skeleton } from "ui";

const inter = Quicksand({ subsets: [], display: "swap" });

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

const HeaderLinkGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const BurgerMenu = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 30px 60px;

  hr {
    width: 100%;
    height: 1px;
    background: ${colors.darkPink2};
    border: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.darkPink2};
`;

const NavigationButton = styled(Button)`
  letter-spacing: inherit;
  font-weight: 200;
`;

const DesktopNameTag = styled.p`
  font-weight: 800;
  margin: 0;
  padding: 6px 16px 10px;
  border-bottom: 1px solid ${colors.black};
`;

const MobileNameTag = styled.p`
  font-size: 18px;
  font-weight: 800;
  margin: 0;
  text-align: center;
  padding: 6px 16px 12px;
  border-bottom: 1px solid ${colors.darkPink2};
  color: ${colors.darkPink2};
`;

const Header = ({ headerLinks }: { headerLinks: boolean }) => {
  const { user, isSuperAdmin, logout } = useBoUserAuthContext();
  const { push } = useRouter();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptions = useMemo(() => {
    const companyId = user?.company?._id ?? "";
    let baseOptions: { text: string; path: string }[] = [];
    if (companyId) {
      baseOptions = [
        { text: "Abbonemang", path: "/admin" },
        { text: "Statistik", path: "/admin/statistic" },
        { text: "Produktförsäljning", path: "/admin/report" },
      ];
    }
    if (isSuperAdmin) {
      return [...baseOptions, { text: "Super Admin", path: "/superadmin" }];
    }
    return baseOptions;
  }, [isSuperAdmin, user?.company?._id]);

  const userNameInitials = useMemo(() => {
    if (!user?.name) return "";
    const [firstName, lastName] = user.name.split(" ");
    return `${firstName[0]}${lastName[0]}`;
  }, [user?.name]);

  const FirstNameAndLastNameInitial = useMemo(() => {
    if (!user?.name) return "";
    const [firstName, lastName] = user.name.split(" ");
    return `${firstName} ${lastName[0]}`;
  }, [user?.name]);

  return (
    <Wrapper>
      <Link href="/">
        <Image src={logo} alt="logo" height={40} />
      </Link>
      {headerLinks && (
        <>
          <HeaderLinkGroup>
            <StyledLink href="/product">Produkten</StyledLink>
            <StyledLink href="/about">Om oss</StyledLink>

            {user?.loading ? (
              <Skeleton variant="rounded" width={64} height={20} />
            ) : user?._id ? (
              <>
                <IconButton
                  aria-label="account circle"
                  size="small"
                  onClick={handleClick}
                  sx={{
                    color: colors.white,
                    background: colors.pink600,
                    fontSize: 14,
                    padding: 1,
                    width: 34,
                    height: 34,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {userNameInitials}
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  sx={{ mt: 0.5 }}
                  disableScrollLock
                >
                  <DesktopNameTag className={inter.className}>
                    {FirstNameAndLastNameInitial}
                  </DesktopNameTag>
                  {menuOptions.map(({ text, path }) => (
                    <MenuItem key={text} onClick={() => push(path)}>
                      {text}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={logout}>Logga ut</MenuItem>
                </Menu>
              </>
            ) : (
              <NavigationButton
                aria-label="logga in"
                variant="text"
                size="small"
                onClick={() => push("/login")}
              >
                Logga in
              </NavigationButton>
            )}
          </HeaderLinkGroup>
          <BurgerMenu>
            <IconButton
              aria-label="menu icon"
              onClick={() => setBurgerMenuOpen(true)}
              sx={{ color: colors.pink600 }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={burgerMenuOpen}
              onClose={() => setBurgerMenuOpen(false)}
            >
              <IconButton
                aria-label="close icon"
                onClick={() => setBurgerMenuOpen(false)}
                sx={{ ml: "auto" }}
              >
                <CloseIcon sx={{ color: colors.pink600 }} />
              </IconButton>
              {user?._id && (
                <MobileNameTag className={inter.className}>
                  {FirstNameAndLastNameInitial}
                </MobileNameTag>
              )}
              <MenuItems
                onClick={() => setBurgerMenuOpen(false)}
                onKeyDown={() => setBurgerMenuOpen(false)}
                className={inter.className}
              >
                <StyledLink href="/product">Produkten</StyledLink>
                <StyledLink href="/about">Om oss</StyledLink>
                {user?.loading ? (
                  <Skeleton variant="rounded" width={64} height={20} />
                ) : user?._id ? (
                  <>
                    <hr />
                    {menuOptions.map(({ text, path }) => (
                      <NavigationButton
                        aria-label={text}
                        key={text}
                        variant="text"
                        size="small"
                        onClick={() => push(path)}
                      >
                        {text}
                      </NavigationButton>
                    ))}
                    <hr />
                    <NavigationButton
                      aria-label="logga ut"
                      variant="text"
                      size="small"
                      onClick={logout}
                    >
                      Logga ut
                    </NavigationButton>
                  </>
                ) : (
                  <>
                    <hr />
                    <NavigationButton
                      aria-label="logga in"
                      variant="text"
                      size="small"
                      onClick={() => push("/login")}
                    >
                      Logga in
                    </NavigationButton>
                  </>
                )}
              </MenuItems>
            </Drawer>
          </BurgerMenu>
        </>
      )}
    </Wrapper>
  );
};

export default Header;
